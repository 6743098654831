<template>
	<div id="my-page">
		<div class="screen-title">マイページ</div>
		<b-container fluid>
			<validation-observer class="w-100" v-slot="{ handleSubmit }" ref="formValidator">
				<b-form class="w-100" role="form" @submit.prevent="handleSubmit(onEditMyPage)">
					<div class="row mx-0 d-flex row-gap-20">
						<card class="my-0 w-100">
							<div class="card-title">基本情報</div>
							<div class="row custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 res_margin_bottom res_margin_bottom px-0">
									<base-input
										alternative
										class="mb-3"
										placeholder="会社名/屋号"
										name="会社名/屋号"
										v-model="model.registerName"
										:rules="{ required: true }"
										label="会社名/屋号"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 res_margin_bottom res_margin_bottom px-0">
									<base-input
										alternative
										placeholder="メールアドレス"
										v-model="model.mailAddress"
										name="メールアドレス"
										label="メールアドレス"
										:rules="{
											required: true,
											validate: [
												[userInfo.id, model.mailAddress],
												'このメールアドレスが存在しました。別のメールアドレスをご記入ください',
												checkEmailValidate,
											],
											email: true,
										}"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col px-0">
									<base-input
										alternative
										class="mb-3"
										placeholder="担当者"
										name="担当者"
										v-model="model.responsibleName"
										label="担当者"
										:inputClasses="'custom-input-class'"
										:labelClasses="'custom-form-input-label'"
										:disabled="true"
									>
									</base-input>
								</div>
							</div>
							<div class="row custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 res_margin_bottom px-0">
									<!-- An added Area -->
									<base-input
										alternative
										placeholder="郵便番号"
										name="郵便番号"
										v-model="model.postcodeAddressJapan"
										@blur="onBlurPostcode"
										label="郵便番号"
										type="text"
										:numberCaption="true"
										:labelClasses="'custom-form-input-label'"
										:noteError="errorNoteMsg"
									>
									</base-input>
									<!-- An added Area -->
								</div>
								<div class="col px-0">
									<base-input
										alternative
										placeholder="住所"
										name="住所"
										v-model="model.address"
										label="住所"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
							</div>
							<div class="row custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 res_margin_bottom px-0">
									<base-input
										:labelClasses="'custom-form-input-label'"
										label="電話番号"
										:numberCaption="true"
									>
										<input
											class="custom-input-number-full"
											type="text"
											placeholder="電話番号"
											v-model="model.phoneNumber"
											@keydown="onKeyDown"
											@blur="checkPhoneNumber"
											:numberCaption="true"
										/>
									</base-input>
								</div>
								<div class="col-md col-xs-12 res_margin_bottom px-0">
									<base-input
										:labelClasses="'custom-form-input-label'"
										label="FAX番号"
										:numberCaption="true"
									>
										<input
											class="custom-input-number-full"
											type="text"
											placeholder="FAX番号"
											v-model="model.faxNumber"
											@keydown="onKeyDown"
											@blur="checkFaxNumber"
										/>
									</base-input>
								</div>
							</div>
							<div class="row custom__mx-3 col-gap-30 pb-27">
								<div class="col-md col-xs-12 res_margin_bottom px-0">
									<base-input
										alternative
										placeholder="ログインID"
										name="ログインID"
										v-model="model.loginId"
										:disabled="true"
										label="ログインID"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 res_margin_bottom px-0">
									<base-input
										alternative
										placeholder="パスワード変更"
										name="パスワード変更"
										v-model="model.loginPassword"
										label="パスワード変更"
										:rules="{ validFormat: true }"
										type="password"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										:note="pwdRegex"
									>
									</base-input>
								</div>
								<div class="col px-0">
									<base-input
										alternative
										placeholder="確認パスワード"
										name="確認パスワード"
										v-model="confirmLoginPassword"
										:rules="{
											confirmPassword: model.loginPassword,
											required: model.loginPassword
												? model.loginPassword.length > 0
												: false,
										}"
										label="確認パスワード"
										type="password"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
							</div>
						</card>
						<card class="w-100 my-0">
							<div class="card-title">LINEアカウントの紐付けURL</div>
							<b-row class="custom__mx-3">
								<base-input
									alternative
									class="mb-3"
									placeholder="LINEアカウントの紐付けURL"
									name="LINEアカウントの紐付けURL"
									v-model="lineVerifyUrl"
									:prepend-icon="copyBtn"
									:textContent="textContent"
									:disabled="true"
									label=""
									@on-icon-click="onCopy(lineVerifyUrl)"
									:inputClasses="'custom-input-class'"
								>
								</base-input
							></b-row>
						</card>
						<card class="my-0 w-100">
							<div
								class="my-0 d-flex flex-collum justify-content-between align-items-center"
							>
								<div class="card-title py-0">サウンド</div>
								<ToggleButton
									:color="{ checked: '#FF8080', unchecked: '#808080' }"
									:labels="{ checked: 'ON', unchecked: 'OFF' }"
									:font-size="17"
									:value="isSoundEnabled"
									:sync="true"
									@change="onChangeSoundEffect"
								/>
							</div>
						</card>
					</div>
					<div class="row mx-0 mt-4 px-0 mb-4">
						<div class="col w-100 d-flex justify-content-center">
							<button native-type="submit" class="customButton customButton__submit">
								変更
							</button>
						</div>
					</div>
				</b-form>
			</validation-observer>
		</b-container>
		<NotiModal
			:notificationModalId="notiModalId"
			:notiContent="notiContent"
			:single-btn="notiSingleBtn"
			@on-click="onAccept"
		/>
	</div>
</template>
<script>
import UserService from '@/services/user.service';
import ClientService from '@/services/client.service';
import TransferService from '@/services/transfer.service';
import zipAddressService from '@/services/zip_address.service';
import socketClient from '@/services/socket/SOCKET';

import { Select, Option } from 'element-ui';
import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';
import ToggleButton from '@/components/ToggleButton/ToggleButton.vue';

import clientPaginationMixin from '@/mixin/clientPaginationMixin';
import autoAddressMixin from '@/mixin/autoAddressMixin';
import postcodeMixin from '@/mixin/postcodeMixin';
import { mapState } from 'vuex';
import CommonConst from '@/util/const';

const wanakana = require('wanakana');
const ALLOW_NULL = ['phone_number', 'fax_number'];
export default {
	name: 'MyPage',
	mixins: [clientPaginationMixin, autoAddressMixin, postcodeMixin],
	components: {
		[Select.name]: Select,
		[Option.name]: Option,
		NotiModal,
		ToggleButton,
	},
	computed: {
		// Get projectId, userId from store
		...mapState(['userInfo']),
	},
	watch: {
		async 'model.postcode'(newPostcode, oldPostcode) {
			if (!newPostcode) return;

			// prevent if it is the first loading
			if (!oldPostcode) return;

			if (newPostcode.length === CommonConst.POST_CODE_LENGTH) {
				const response = await zipAddressService.get(newPostcode);
				const address = response.data;
				this.model.address = address.fullAddress;
			} else if (newPostcode.length > CommonConst.POST_CODE_LENGTH) {
				this.model.postcode = oldPostcode;
			} else {
				this.model.address = '';
			}
		},
	},
	data() {
		return {
			notiModalId: 'my-page-noti',
			notiContent: '',
			notiSingleBtn: '',
			pwdRegex: CommonConst.PWD_REGEX,
			model: {
				registerName: undefined,
				companyName: undefined,
				postcode: undefined,
				address: undefined,
				clientRemark: undefined,
				phoneNumber: undefined,
				faxNumber: undefined,
				mailAddress: undefined,
				loginId: undefined,
				loginPassword: undefined,
				confirmLoginPassword: undefined,
				bankName: undefined,
				bankBranchName: undefined,
				bankAccountType: undefined,
				bankAccountHolder: undefined,
				bankAccountNumber: undefined,
				webSiteName: undefined,
				instagramAccount: undefined,
				facebookAccount: undefined,
				twitterAccount: undefined,
				imprint: undefined,
				projectRemark: undefined,
				responsibleName: undefined,
				// An added area
				postcodeAddressJapan: '',
				// An added area
			},
			sealSrc: '',
			confirmLoginPassword: undefined,
			randomPassword: Math.random().toString(36).slice(2, 10),
			classificationSelect: '',
			loginUserId: '',
			radioOptions: [
				{
					text: '法人',
					value: '2',
				},
				{
					text: '個人',
					value: '1',
				},
			],
			bankAccountTypeOptions: [
				{
					value: '普通',
				},
				{
					value: '当座',
				},
			],
			lineVerifyUrl: '',
			textContent: 'URLをコピー',
			copyBtn: 'copy-btn',
			editSuccessMessage: '変更が完了しました',
			editErrorMessages: '変更できません',
			alertMessage: '',
			alertType: '',
			showAlert: false,
			isSoundEnabled: false,
			editType: 0,
		};
	},
	methods: {
		async onChangeSoundEffect(data) {
			this.isSoundEnabled = data.value;

			const [response, error] = await await UserService.update(this.userInfo.id, {
				sound_enabled: data.value ? 1 : 0,
			});

			if (response) {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userInfo?.id,
						event_name: 'toggle_client_sound_enabled',
						content: data.value,
					},
					room: 'user' + this.userInfo?.id,
				});
			}
		},

		checkPhoneNumber() {
			if (this.model.phoneNumber) {
				this.model.phoneNumber = this.model.phoneNumber.replaceAll('-', '');
			}
		},
		checkFaxNumber() {
			if (this.model.faxNumber) {
				this.model.faxNumber = this.model.faxNumber.replaceAll('-', '');
			}
		},
		checkPostCodeNumber() {
			if (this.model.postcode) {
				this.model.postcode = this.model.postcode.replaceAll('-', '');
			}
		},
		onKeyDown(event) {
			const acceptedKeys = [
				'Backspace',
				'Delete',
				'ArrowLeft',
				'ArrowRight',
				'Minus',
				'NumpadSubtract',
			];
			if (
				(isNaN(Number(event.key)) && !acceptedKeys.includes(event.code)) ||
				event.code == 'Space'
			) {
				event.preventDefault();
			}
		},
		onCopy(copyData, inputIdx) {
			const copyDataFormatted = `${CommonConst.PRE_TEXT_LINE_LINK}${copyData}`;
			let self = this;
			this.$copyText(copyDataFormatted).then(
				function (e) {
					self.textContent = 'コピー済み';
					setTimeout(() => {
						self.textContent = 'URLをコピー';
					}, 1000);
					console.log(e);
				},
				function (e) {
					self.textContent = 'コピー失敗';
					console.log(e);
				}
			);
		},
		onBankAccountHolderChange() {
			this.model.bankAccountHolder = wanakana.toKatakana(this.model.bankAccountHolder, {
				IMEMode: true,
			});
		},
		isNumeric(evt) {
			var theEvent = evt || window.event;
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
			var regex = /[0-9]/;
			if (!regex.test(key)) {
				theEvent.returnValue = false;
				if (theEvent.preventDefault) theEvent.preventDefault();
			}
		},
		maxLengthCheck() {
			if (this.model.bankAccountNumber > 7) {
				this.model.bankAccountNumber = this.model.bankAccountNumber.slice(0, 7);
			}
		},

		async checkEditType() {
			let samePass = false;
			if (this.model.loginPassword == this.randomPassword) {
				samePass = true;
			} else {
				let response = await UserService.verifyPassword(
					this.userInfo.id,
					this.model.loginPassword
				);
				samePass = response[0].isCorrect;
			}
			let sameName = this.model.registerName === this.userInfo.register_name;
			let sameMail = this.model.mailAddress === this.userInfo.email_address;
			// 0: All, 1: Pass + Name, 2: Pass + Mail, 3: Name + Mail, 4: Pass, 5: Name, 6: Mail , 7: None
			if (samePass && sameName && sameMail) this.editType = 0;
			else if (samePass && sameName && !sameMail) this.editType = 1;
			else if (samePass && !sameName && sameMail) this.editType = 2;
			else if (!samePass && sameName && sameMail) this.editType = 3;
			else if (samePass && !sameName && !sameMail) this.editType = 4;
			else if (!samePass && sameName && !sameMail) this.editType = 5;
			else if (!samePass && !sameName && sameMail) this.editType = 6;
			else this.editType = 7;
		},

		async modalContent() {
			await this.checkEditType();
			return this.editType == 0
				? '変更します。\nよろしいですか？'
				: this.editType == 1
				? 'メールを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 2
				? 'お名前を変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 3
				? 'パスワードを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 4
				? 'お名前、メールを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 5
				? 'メール、パスワードを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: this.editType == 6
				? 'お名前、パスワードを変更した場合、即時で再度ログインが必須です。\n変更しますか？'
				: 'お名前、メール、パスワードを変更した場合、\n即時で再度ログインが必須です。\n変更しますか？';
		},

		async onEditMyPage() {
			this.notiSingleBtn = false;
			this.notiContent = await this.modalContent();
			this.$bvModal.show(`${this.notiModalId}`);
		},

		onAccept() {
			this.editMyPageInfo();
		},

		async editMyPageInfo() {
			this.model.postcodeAddressJapan = this.convertToHalfWidth(
				this.model.postcodeAddressJapan
			);
			let userInfo = {
				register_name: this.model.registerName,
				address: this.model.address,
				email_address: this.model.mailAddress,
				fax_number: this.model.faxNumber,
				phone_number: this.model.phoneNumber,
				// postal_code: this.model.postcode,
				postal_code: this.model.postcodeAddressJapan,
				update_user: this.loginUserId,
				login_password:
					this.model.loginPassword == this.randomPassword
						? undefined
						: this.model.loginPassword,
				is_client: true,
			};
			let contents = this.deleteNullFields(userInfo);
			let [response, error] = await await UserService.update(
				this.userInfo.id,
				contents
			);
			if (response) {
				console.log('Edit My Page Success');
				if (this.editType != 0) {
					document.cookie =
						'access_token_cookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
					this.$router.push({ path: '/adminApp/login' });
				} else {
					this.notiSingleBtn = true;
					this.notiContent = '最新情報に変更されました。';
					this.$bvModal.show(`${this.notiModalId}`);
					this.userInfo.register_name = this.model.registerName;
					this.$store.commit('setUserInfo', this.userInfo);
				}
			} else {
				this.showAlert = true;
				this.alertType = 'danger';
				this.alertMessage = this.editErrorMessages;
			}
		},

		async getLineVerifyUrl() {
			if (this.userInfo['line_verify_code']) {
				let [response, error] = await UserService.getLineVerifyUrl(this.userInfo['id']);
				if (response) {
					this.lineVerifyUrl = response.line_verify_url;
				}
				return;
			}
			let [response, error] = await UserService.createLineVerifyUrl(this.userInfo.id);
			if (response) {
				this.lineVerifyUrl = response.line_verify_url;
			}
		},

		async getUserInfo() {
			this.model.registerName = this.userInfo.register_name;
			this.model.companyName = this.userInfo.company_name;
			// this.model.postcode = this.userInfo.postal_code;
			this.model.postcodeAddressJapan = this.userInfo.postal_code;
			this.model.address = this.userInfo.address;
			this.model.remark = this.userInfo.remark;
			this.model.phoneNumber = this.userInfo.phone_number;
			this.model.faxNumber = this.userInfo.fax_number;
			this.model.mailAddress = this.userInfo.email_address;
			this.model.loginId = this.userInfo.username;
			this.model.loginPassword = this.userInfo.login_password;
			this.model.bankName = this.userInfo.bank_name;
			this.model.bankBranchName = this.userInfo.branch_name;
			this.model.bankAccountType = this.userInfo.account_type;
			this.model.bankAccountHolder = this.userInfo.account_holder;
			this.model.bankAccountNumber = this.userInfo.account_number;
			this.model.projectRemark = this.userInfo.remark;
			this.classificationSelect = this.userInfo.type;
			this.model.companySeal = this.userInfo.company_seal
				? this.userInfo.company_seal
				: undefined;
			this.model.loginPassword = this.randomPassword;
			this.confirmLoginPassword = this.randomPassword;
			this.isSoundEnabled = !!this.userInfo.sound_enabled;
			this.getCompanySeal();
			this.getClientInfo();
			this.getLineVerifyUrl();
		},

		deleteNullFields(obj) {
			for (let key in obj) {
				if ((obj[key] == undefined || obj[key] == '') && !CommonConst.IGNORE_FIELD.includes(key)) {
					if (key === 'account_number') {
						obj[key] = null;
					} else {
						delete obj[key];
					}
				}
			}
			return obj;
		},
		async getCompanySeal() {
			if (this.userInfo.company_seal && this.userInfo.company_seal.key) {
				let [responseTransferGet, errorTransfer] = await TransferService.get(
					this.userInfo.company_seal.key,
					CommonConst.EXPIRATION
				);
				if (responseTransferGet) {
					this.sealSrc = responseTransferGet.link;
					this.model.companySeal.link = responseTransferGet.link;
				} else {
					console.log(errorTransfer);
				}
			}
		},
		onUploadFile(files) {
			[...files].forEach((file) => {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = async () => {
					let [uploadResponse, errorPostImg] = await await TransferService.postImg({
						content: reader.result.toString(),
						file_name: file.name,
						user_id: this.userInfo.id,
					});
					if (uploadResponse) {
						let key = uploadResponse.key;
						this.getLinkPreview(file, key);
					} else {
						console.log(errorPostImg);
					}
				};
			});
		},
		async getLinkPreview(file, key) {
			let [getResponse, errorGet] = await await TransferService.get(
				key,
				CommonConst.EXPIRATION
			);
			if (getResponse) {
				let linkPreview = getResponse.link;
				this.model.companySeal = {
					link: linkPreview,
					file_name: file.name,
					key: key,
				};
				this.sealSrc = linkPreview;
			} else {
				console.log('errorGet', errorGet);
			}
		},
		onAlertVisibleChange(data) {
			this.showAlert = data;
		},
		async checkEmailValidate(userId, email) {
			let [response, error] = await await UserService.checkExists({
				email_address: email,
			});
			if (error) {
				console.log(error);
			}
			if (response) {
				if (response.id == userId) {
					return true;
				}
				return !response.exists;
			}
		},
		async getClientInfo() {
			let [response, error] = await await ClientService.get(this.userInfo.id);
			if (error) {
				console.log(error);
			}
			if (response) {
				this.model.responsibleName = response[0].responsible_name;
			}
		},
	},

	async mounted() {
		this.getUserInfo();
	},
};
</script>
<style scoped>
.seal-img {
	width: 70px;
	height: 70px;
	object-fit: contain;
	margin-bottom: 10px;
}
</style>
